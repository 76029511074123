.gradient-background {
    background: linear-gradient(252deg,#ff0000,#000000,#721d7c,#0d264e);
    background-size: 240% 240%;
    animation: gradient-animation 12s ease infinite;
  }
  
  @keyframes gradient-animation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }